import { React } from "react";
import { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { ValidateEmail, normalize } from "../../../config/utility";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { addSettings, addMsaInfo } from "../../../actions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  listSubUsers,
  getSingleUser,
  editSubUser,
} from "./../../../config/api_calls";
import HelperClass from "./../../../config/helperClass";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import PageTitle from "../ExtraComponents/PageTitle";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MutextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();
class SubUsers extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      editUserData: [],
      c_password: "",
      errorMessage: "",
      errorMessageEdit: "",
      agentId: "",
      successMessage: "",
      successMessageEdit: "",
      eSuccessMessage: "",
      eErrorMessage: "",
      waitMessage: "",
      tableLoader: true,
      name: "",
      comp_name: "",
      title: "",
      email: "",
      password: "",
      errorEmail: false,
      errorPass: false,
      errorcPass: false,
      errorName: false,
      errorMessage: "",
      errorCompName: false,
      errorTitle: false,
      disabled: false,
      successMessage: "",
      confirm_password: "",
      errorConfirm: false,
      disableResendBtn: false,
      phone: "",
      user_id: "",
      account_status: "",
      errorPhone: false,
      agent_id: "",
      serviceData: [],
      signedData: [],
      unsignedData: [],
      isEmailSending: false,
      unsigned_email_agreement_id: 0,
      revoke_reason: "",
      isRevokeReasonActive: false,
      columnServices: [
        { name: "Title" },
        { name: "Company Name" },
        { name: "Email" },
        { name: "Phone" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setDomain(value, tableMeta)}</div>;
            },
          },
        },

        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.editUserForm(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Sub User"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Sub User</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/sub_users/user_roles/" + value
                                    )
                                  }
                                  title="Add / Edit User Roles"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>User Roles</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listSubUsers(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  editUserForm = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[6];
    const servicesResponce = await getSingleUser(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        user_id: servicesResponce.data.data[0]["id"],
        name: servicesResponce.data.data[0]["name"],
        comp_name: servicesResponce.data.data[0]["comp_name"],
        title: servicesResponce.data.data[0]["title"],
        email: servicesResponce.data.data[0]["email"],
        phone: servicesResponce.data.data[0]["phone"],
        account_status: servicesResponce.data.data[0]["account_status"],
        tableLoader: false,
      });
      window.$("#modalFormDetail").modal("show");
    } else {
      // window.location.replace("/error");
    }
  };

  setDomain = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[5];

    if (domain_str === "active") {
      return (
        <span className="badge badge-outline-success">
          Active
          <em className="icon ni ni-check-round-cut" title="Verified"></em>
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-danger">
          Inactive
          <em className="icon ni ni-alert-circle" title="Verified"></em>
        </span>
      );
    }
  };

  show_email = () => {
    this.setState({
      send_email: "1",
    });
  };

  fieldValidation = () => {
    let { name, email, password, confirm_password, phone, comp_name, title } =
      this.state;

    if (comp_name === "") {
      this.setState({
        errorCompName: true,
        errorMessage: "Company Name is required.",
        errorcPass: false,
      });
      return false;
    } else if (name === "") {
      this.setState({
        errorCompName: false,
        errorName: true,
        errorMessage: "Full Name is required.",
        errorcPass: false,
      });
      return false;
    } else if (title === "") {
      this.setState({
        errorCompName: false,
        errorName: false,
        errorTitle: true,
        errorMessage: "Title is required.",
        errorcPass: false,
      });
      return false;
    } else if (phone === "") {
      this.setState({
        errorPhone: true,
        errorEmail: false,
        errorMessage: "Phone is required.",
        errorTitle: false,
        errorcPass: false,
      });
      return false;
    } else if (normalize(phone) === null) {
      this.setState({
        errorPhone: true,
        errorEmail: false,
        errorMessage: "Invalid phone format.",
        errorcPass: false,
      });
      return false;
    } else if (password !== "") {
      if (password.length < 8) {
        this.setState({
          errorPass: true,
          errorEmail: false,
          errorcPass: false,
          errorPhone: false,
          errorMessage: "Use 8 characters or more for your password",
        });
        return false;
      } else if (confirm_password === "") {
        this.setState({
          errorPass: false,
          errorcPass: true,
          errorEmail: false,
          errorPhone: false,
          errorMessage: "Confirm password is required.",
        });
        return false;
      } else if (confirm_password.length < 8) {
        this.setState({
          errorPass: false,
          errorcPass: true,
          errorEmail: false,

          errorPhone: false,
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        return false;
      } else if (password !== confirm_password) {
        this.setState({
          errorPass: false,
          errorcPass: true,
          errorEmail: false,

          errorPhone: false,
          errorMessage: "Password and confirm password does not match.",
        });
        return false;
      }
    } else {
      this.setState({
        errorcPass: false,
        errorPass: false,
        errorEmail: false,
        errorMessage: false,
        errorConfirm: false,
        errorPhone: false,
        disabled: true,
      });
    }
    return true;
  };

  formUpdateSubmit = async () => {
    let {
      name,
      email,
      password,
      confirm_password,
      phone,
      comp_name,
      title,
      user_id,
      account_status,
    } = this.state;

    if (this.fieldValidation() === true) {
      let apiResponce = await editSubUser(
        auth.getAccount(),
        auth.getToken(),
        name,
        email,
        password,
        phone,
        comp_name,
        title,
        user_id,
        account_status
      );
      console.log("Edit User RES: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessageEdit: "User updated successfully.",
          errorMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(() => {
          window.$("#modalFormDetail").modal("hide");
          this.componentDidMount();
          //this.props.props.history.push("/sub_users");
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Sub Users" icon="icon ni ni-user-list" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() =>
                                this.addMenuHistory("/sub_users/adduser")
                              }
                              className="btn btn-primary"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Sub User
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-edit"></em> Edit Sub User
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <form method="post" encType="multipart/form-data">
                          <div className="row g-4">
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="comp_name"
                                name="comp_name"
                                type="text"
                                label="Company Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.comp_name}
                                onChange={this.handleChange}
                                helperText="Enter the company name."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="name"
                                name="name"
                                type="text"
                                label="Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.name}
                                onChange={this.handleChange}
                                helperText="Enter the sub user name."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="title"
                                name="title"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                helperText="Enter the sub user title here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="phone"
                                name="phone"
                                type="text"
                                inputProps={{ maxLength: 14 }}
                                label="Phone"
                                value={this.state.phone}
                                onChange={this.handleChange}
                                helperText="Enter the phone number here."
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="email"
                                name="email"
                                type="email"
                                label="Email Address"
                                value={this.state.email}
                                onChange={this.handleChange}
                                helperText="Email address here. (Read Only)"
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="account_status"
                                name="account_status"
                                select
                                label="Status"
                                value={this.state.account_status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="e.g Active or Inactive"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="status1" value="active">
                                  Active
                                </option>
                                <option key="status0" value="inactive">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="password"
                                name="password"
                                type="password"
                                label="Password"
                                value={this.state.password}
                                inputProps={{ maxLength: 100 }}
                                onChange={this.handleChange}
                                helperText="Enter your password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                id="c_password"
                                name="c_password"
                                type="password"
                                label="Confirm Password"
                                value={this.state.c_password}
                                inputProps={{ maxLength: 100 }}
                                onChange={this.handleChange}
                                helperText="Enter the confirm password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="col-md-12">
                              <p>
                                NOTE: Password is optional. If you do not want
                                to change the password, leave the last two
                                fields empty.
                              </p>
                            </div>

                            <div style={{ clear: "both" }}></div>
                            <hr />
                            <div className="form-group text-right col-md-12">
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.formUpdateSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                {/* Table 5th Col Start */}
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Sub Users"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                </div>
                {/* Table 5th Col End */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubUsers);
