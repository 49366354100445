import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  getAgentSignup,
  regenerateAgentLink,
  cancelAgentLink,
  getSmtpSettings,
  addSmtpSettings,
  getPaymentSettings,
  addPaymentSettings,
  verifySmtpSettings,
  getQuotationSettings,
  addQuotationSettings,
  getClientNotes,
  editClientNotes,
} from "./../../../config/reseller_api_calls";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Upgrades extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
      payment_reg_button: false,
      quotation_button: false,
      bank_account_details: "",
      wire_transfer_details: "",
      ACH_details: "",
      quotation_notes: "",
      payment_terms: "",
      selected_payment_terms: null,
      currency: "",
      selected_currency: null,
      contact_person: "",
      contact_email: "",
      validity: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      smtp_host: "",
      smtp_user: "",
      smtp_password: "",
      smtp_method: "tls",
      smtp_port: "",
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
      from_email: "",
      errorFromEmail: "",
      is_working: "",
      notifications_email: "",
      billing_email: "",
      sales_email: "",
      settings_type: "",
      clientNotes: "",
      otherSettingSuccessMessage: "",
      otherSettingErrorMessage: "",
      client_setting_button: false,
    };
    
  }

  async componentDidMount() {
    //settings_type
    let settings_type = this.props.props.match.params.settings_type;
    console.log("settings_type: ", settings_type);
    this.setState({
      settings_type: settings_type,
    });
    if (settings_type === "smtp_settings") {
      this.get_smtp_settings();
    }
    if (settings_type === "payment_settings") {
      this.get_payment_settings();
    }
    if (settings_type === "quotation_settings") {
      this.get_quotation_settings();
    }
    if (settings_type === "other_settings") {
      this.get_other_settings();
    }
    const servicesResponce = await getAgentSignup(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        login_url: servicesResponce.data.login_url,
        logo: servicesResponce.data.logo,
        signature_image: servicesResponce.data.signature,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  regenerate_link = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await regenerateAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link generated successfully.",
        reg_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  cancel_link = async () => {
    this.setState({
      cancel_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await cancelAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("cancel_link: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link removed successfully.",
        cancel_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  get_smtp_settings = async () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getSmtpSettings(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("get_smtp_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        smtp_host: servicesResponce.data.data[0].smtp_host,
        smtp_user: servicesResponce.data.data[0].smtp_user,
        smtp_password: servicesResponce.data.data[0].smtp_password,
        smtp_method: servicesResponce.data.data[0].smtp_method,
        smtp_port: servicesResponce.data.data[0].smtp_port,
        from_email: servicesResponce.data.data[0].from_email,
        notifications_email: servicesResponce.data.data[0].notifications_email,
        is_working: servicesResponce.data.data[0].is_working,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "no_record"
    ) {
      this.setState({
        smtp_host: "",
        smtp_user: "",
        smtp_password: "",
        smtp_method: "tls",
        smtp_port: "",
        from_email: "",
        notifications_email: "",
        is_working: "no",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
      notifications_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    }
    if (notifications_email === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Notifications email is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (notifications_email !== "") {
      if (this.validateEmailAdd(notifications_email) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid notifications email format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      const servicesResponce = await addSmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email,
        notifications_email
      );
      //console.log("addSmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings added successfully.",
          smtp_reg_button: false,
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  //Test SMTP Settings

  verify_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_test_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      const servicesResponce = await verifySmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email
      );
      console.log("verifySmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings tested successfully.",
          smtp_test_button: false,
          is_working: "yes",
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  get_payment_settings = async () => {
    this.setState({
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
    });
    const servicesResponce = await getPaymentSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_payment_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        paymentErrorMessage:
          "There is some error while getting payment settings.",
        paymentSuccessMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        bank_account_details: servicesResponce.data.bank_details,
        wire_transfer_details: servicesResponce.data.wire_transfer_details,
        ACH_details: servicesResponce.data.ACH_details,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        bank_account_details: "",
        wire_transfer_details: "",
        ACH_details: "",
      });
    } else {
      this.setState({
        paymentErrorMessage:
          "There is some error while getting payment settings.",
        paymentSuccessMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_payment_settings = async () => {
    let { bank_account_details, wire_transfer_details, ACH_details} = this.state;
    this.setState({
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
      payment_reg_button: true,
    });
    var is_validated = true;

    if (bank_account_details === "") {
      this.setState({
        paymentErrorMessage: "Bank details are required.",
        payment_reg_button: false,
      });
      is_validated = false;
    }
    if (wire_transfer_details === "") {
      this.setState({
        paymentErrorMessage: "Wire transfer details are required.",
        payment_reg_button: false,
      });
      is_validated = false;
    }
    if (ACH_details === "") {
      this.setState({
        paymentErrorMessage: "Wire transfer details are required.",
        payment_reg_button: false,
      });
      is_validated = false;
    }

    if (is_validated) {
      const servicesResponce = await addPaymentSettings(
        auth.getAccount(),
        auth.getToken(),
        bank_account_details,
        wire_transfer_details,
        ACH_details
      );
      console.log("add_payment_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          paymentErrorMessage:
            "There is some error while adding payment settings.",
          paymentSuccessMessage: "",
          payment_reg_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          paymentErrorMessage: "",
          paymentSuccessMessage: "Payment settings added successfully.",
          payment_reg_button: false,
        });
      } else {
        this.setState({
          paymentErrorMessage:
            "There is some error while adding payment settings.",
          paymentSuccessMessage: "",
          payment_reg_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ paymentErrorMessage: "", paymentSuccessMessage: "" });
    }, 5000);
  };

  get_other_settings = async () => {
    this.setState({
      clientNotes: "",
      otherSettingSuccessMessage: "",
      otherSettingErrorMessage: "",
      client_setting_button: false,
      tableLoader: true,
    });
    const notesResponce = await getClientNotes(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("Client Notes  Res: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      this.setState({
        clientNotes: notesResponce.data.data.welcome_content,
        tableLoader: false,
        client_setting_button: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  saveClientNotes = async () => {
    window.scrollTo(0, 0);
    const clientNotes = this.state.clientNotes;
    var validated = true;
    if (clientNotes === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        otherSettingErrorMessage: "Please enter the required field.",
        otherSettingSuccessMessage: "",
      });
    } else {
      this.setState({
        otherSettingErrorMessage: "",
        otherSettingSuccessMessage: "",
        disabled: true,
        client_setting_button: true,
      });

      const saveResponce = await editClientNotes(
        auth.getAccount(),
        auth.getToken(),
        clientNotes
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          otherSettingErrorMessage:
            "There is some error while updating the Content.",
          otherSettingSuccessMessage: "",
          disabled: false,
          client_setting_button: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          otherSettingErrorMessage: "",
          otherSettingSuccessMessage: "Content updated successfully.",
          disabled: false,
          client_setting_button: false,
        });
        setTimeout(() => {
          this.setState({
            otherSettingErrorMessage: "",
            otherSettingSuccessMessage: "",
            disabled: false,
            client_setting_button: false,
          });
          this.get_other_settings();
        }, 3000);
      } else {
        this.setState({
          otherSettingErrorMessage: saveResponce.data.message,
          otherSettingSuccessMessage: "",
          disabled: false,
          client_setting_button: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  handleEditorClientNotes = (content, editor) => {
    this.setState({
      clientNotes: content,
    });
  };

  getInitialSelectedOption = (value) => {
    // Retrieve the initial selected option from your options array
    let  options  = this.getOption(); // Replace with your actual options array
    
    if(options){
      return options.find(option => option.value === value) || null;
    }
    
  }

  getInitialSelectedTermOption = (value) => {
    // Retrieve the initial selected option from your options array
    let  options  = this.getTermOptions(); // Replace with your actual options array
    
    if(options){
      return options.find(option => option.value === value) || null;
    }
    
  }

  getOption = () => {
    const currencyOptions = [
      { value: "usd", label: "USD - US Dollar" },
      { value: "eur", label: "EUR - Euro" },
      { value: "gbp", label: "GBP - British Pound" },
      { value: "jpy", label: "JPY - Japanese Yen" },
      { value: "cny", label: "CNY - Chinese Yuan" },
      { value: "inr", label: "INR - Indian Rupee" },
      { value: "aud", label: "AUD - Australian Dollar" },
      { value: "cad", label: "CAD - Canadian Dollar" },
      { value: "chf", label: "CHF - Swiss Franc" },
      { value: "sek", label: "SEK - Swedish Krona" },
      { value: "nzd", label: "NZD - New Zealand Dollar" },
      { value: "sgd", label: "SGD - Singapore Dollar" },
      { value: "hkd", label: "HKD - Hong Kong Dollar" },
      { value: "krw", label: "KRW - South Korean Won" },
      { value: "brl", label: "BRL - Brazilian Real" },
      { value: "zar", label: "ZAR - South African Rand" },
      { value: "rub", label: "RUB - Russian Ruble" },
      { value: "try", label: "TRY - Turkish Lira" },
      { value: "mxn", label: "MXN - Mexican Peso" },
      { value: "idr", label: "IDR - Indonesian Rupiah" },
    ];
    return currencyOptions
  }

  getTermOptions = () => {
    const currencyOptions = [
      { value: "Net 30", label: "Net 30" },
      { value: "Net 60", label: "Net 60" },
      { value: "Net 90", label: "Net 90" },
      { value: "Due on Receipt", label: "Due on Receipt" },
      { value: "2/10 Net 30", label: "2/10 Net 30" },
      { value: "1/10 Net 30", label: "1/10 Net 30" },
      { value: "2/10 Net 60", label: "2/10 Net 60" },
      { value: "3/10 Net 30", label: "3/10 Net 30" },
      { value: "Net 45", label: "Net 45" },
      { value: "Net 120", label: "Net 120" },
      { value: "Cash on Delivery (COD)", label: "Cash on Delivery (COD)" },
      { value: "Cash in Advance (CIA)", label: "Cash in Advance (CIA)" },
      { value: "Stage Payments", label: "Stage Payments" },
      { value: "End of Month (EOM)", label: "End of Month (EOM)" },
    ];
    return currencyOptions
  }

  get_quotation_settings = async () => {
    this.setState({
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
    });
    const servicesResponce = await getQuotationSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_quotation_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        quotationErrorMessage:
          "There is some error while getting quotation settings.",
        quotationSuccessMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        quotation_notes: servicesResponce.data.quotationSettings[0]["quotation_notes"],
        payment_terms: servicesResponce.data.quotationSettings[0]["payment_terms"],
        selected_payment_terms: this.getInitialSelectedTermOption(servicesResponce.data.quotationSettings[0]["payment_terms"]),
        currency: servicesResponce.data.quotationSettings[0]["currency"],
        selected_currency: this.getInitialSelectedOption(servicesResponce.data.quotationSettings[0]["currency"]),
        contact_person: servicesResponce.data.quotationSettings[0]["contact_person"],
        contact_email: servicesResponce.data.quotationSettings[0]["contact_email"],
        validity: servicesResponce.data.quotationSettings[0]["validity"],
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        quotation_notes: "",
        payment_terms: "",
        currency: "",
        contact_person: "",
        contact_email: "",
        validity: "",
      });
    } else {
      this.setState({
        quotationErrorMessage:
          "There is some error while getting quotation settings.",
        quotationSuccessMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_quotation_settings = async () => {
    let {
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
    } = this.state;
    this.setState({
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
      quotation_button: true,
    });
    var is_validated = true;

    /*if (quotation_notes === "") {
      this.setState({
        quotationErrorMessage: "Quotation notes are required.",
        quotation_button: false,
      });
      is_validated = false;
    } else if (payment_terms === "") {
      this.setState({
        quotationErrorMessage: "Payment terms are required.",
        quotation_button: false,
      });
      is_validated = false;
    }*/

    if (is_validated) {
      const servicesResponce = await addQuotationSettings(
        auth.getAccount(),
        auth.getToken(),
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
      );
      console.log("add_quotation_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          quotationErrorMessage:
            "There is some error while adding quotation settings.",
            quotationSuccessMessage: "",
            quotation_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          quotationErrorMessage: "",
          quotationSuccessMessage: "Quotation settings added successfully.",
          quotation_button: false,
        });
      } else {
        this.setState({
          quotationErrorMessage:
            "There is some error while adding quotation settings.",
            quotationSuccessMessage: "",
            quotation_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ quotationErrorMessage: "", quotationSuccessMessage: "" });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        currency: changed_value.value,
        selected_currency: changed_value,
      }));
    } else {
      this.setState({ selected_currency: null });
    }
  };

  handleChangeSearchTerms = async (changed_value) => {
    console.log("handleChangeSearchTerms: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        payment_terms: changed_value.value,
        selected_payment_terms: changed_value,
      }));
    } else {
      this.setState({ selected_payment_terms: null });
    }
  };

  handleEditorBank = (content, editor) => {
    this.setState({
      quotation_notes: content,
    });
    //console.log("Content was updated:", content);
  };

  handleEditorWire = (content, editor) => {
    this.setState({
      wire_transfer_details: content,
    });
    //console.log("Content was updated:", content);
  };

  handleEditorAch = (content, editor) => {
    this.setState({
      ACH_details: content,
    });
    //console.log("Content was updated:", content);
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em className="icon ni ni-setting"></em> Settings
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p> &nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                <div className="col-xxl-12">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "general_settings" ||
                              this.state.settings_type === ""
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem5"
                          >
                            <em className="icon ni ni-setting"></em>
                            <span>General Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "smtp_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem6"
                            onClick={() => this.get_smtp_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>SMTP Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "payment_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem7"
                            onClick={() => this.get_payment_settings()}
                          >
                            <em className="icon ni ni-cc-alt"></em>
                            <span>Payment Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "quotation_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItemQuotation"
                            onClick={() => this.get_quotation_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>Quotation Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "other_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#other_settings"
                            onClick={() => this.get_other_settings()}
                          >
                            <em className="icon ni ni-setting"></em>
                            <span>Other Settings</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={
                            this.state.settings_type === "general_settings" ||
                            this.state.settings_type === ""
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem5"
                        >
                          <div className="row g-gs">
                            <div className="col-xxl-6">
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-user"></em>{" "}
                                      Agent / Channel Partner Signup Link
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <>
                                        {this.state.errorMessage !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>{this.state.errorMessage}</p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessage !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {this.state.successMessage}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="example-alert">
                                          <div className="alert alert-light alert-icon">
                                            <em className="icon ni ni-alert-circle"></em>{" "}
                                            <strong>NOTE: </strong> Anyone with
                                            this link will be able to create a
                                            super agent for this reseller. Be
                                            very careful where and how you send
                                            this! It is also a good idea to make
                                            sure you have any supporting
                                            documents and contracts in place
                                            before you allow the reseller to
                                            have its first super agent.{" "}
                                          </div>
                                        </div>

                                        <div
                                          className="caption-text"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-light">
                                              <em className="icon ni ni-link"></em>{" "}
                                              {this.state.signup_link &&
                                              this.state.signup_link !== "" ? (
                                                <>{this.state.signup_link}</>
                                              ) : (
                                                <>
                                                  Agent signup link is not
                                                  available. Please create a new
                                                  one by clicking on
                                                  "Regenerate" button.
                                                </>
                                              )}
                                            </div>
                                          </div>
                                          {this.state.reg_button === false ? (
                                            <button
                                              className="btn btn-primary"
                                              style={{ marginRight: "15px" }}
                                              onClick={() =>
                                                this.regenerate_link()
                                              }
                                            >
                                              <em className="icon ni ni-swap"></em>{" "}
                                              Regenerate
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary"
                                              style={{ marginRight: "15px" }}
                                              disabled
                                            >
                                              <em className="icon ni ni-swap"></em>{" "}
                                              Regenerate
                                              <div
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            </button>
                                          )}

                                          {this.state.cancel_button ===
                                          false ? (
                                            <button
                                              className="btn btn-danger"
                                              style={{ marginRight: "15px" }}
                                              onClick={() => this.cancel_link()}
                                            >
                                              <em className="icon ni ni-cross-round"></em>{" "}
                                              Cancel
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-danger"
                                              style={{ marginRight: "15px" }}
                                              disabled
                                            >
                                              <em className="icon ni ni-cross-round"></em>{" "}
                                              Cancel
                                              <div
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            </button>
                                          )}
                                          {this.state.signup_link &&
                                          this.state.signup_link !== "" ? (
                                            <CopyToClipboard
                                              text={this.state.signup_link}
                                              onCopy={() =>
                                                this.setState({ copied: true })
                                              }
                                            >
                                              <button className="btn btn-success">
                                                <em className="icon ni ni-copy"></em>{" "}
                                                Copy to clipboard{" "}
                                                {this.state.copied ? (
                                                  <span
                                                    style={{ color: "white" }}
                                                  >
                                                    <em className="icon ni ni-done"></em>
                                                  </span>
                                                ) : null}
                                              </button>
                                            </CopyToClipboard>
                                          ) : null}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-signin"></em>{" "}
                                      Agent Login URL
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    <div className="example-alert">
                                      <div
                                        className="alert alert-light alert-icon"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        <em className="icon ni ni-link"></em>{" "}
                                        {this.state.login_url &&
                                        this.state.login_url !== "" ? (
                                          <>{this.state.login_url}</>
                                        ) : (
                                          <>
                                            Agent login link is not available.
                                            Please create a new one by clicking
                                            on "Regenerate" button.
                                          </>
                                        )}
                                      </div>
                                      {/*{this.state.reg_button_login === false ? (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                onClick={() => this.regenerate_link()}
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                disabled
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            )}*/}
                                      {this.state.login_url &&
                                      this.state.login_url !== "" ? (
                                        <CopyToClipboard
                                          text={this.state.login_url}
                                          onCopy={() =>
                                            this.setState({ copied2: true })
                                          }
                                        >
                                          <button className="btn btn-success">
                                            <em className="icon ni ni-copy"></em>{" "}
                                            Copy to clipboard{" "}
                                            {this.state.copied2 ? (
                                              <span style={{ color: "white" }}>
                                                <em className="icon ni ni-done"></em>
                                              </span>
                                            ) : null}
                                          </button>
                                        </CopyToClipboard>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6">
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-camera"></em>{" "}
                                      Upload Logo and Signature
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <>
                                        <div className="nk-block">
                                          <div className="card card-bordered">
                                            <div className="card-inner">
                                              <h5 className="card-title">
                                                <em className="icon ni ni-camera"></em>{" "}
                                                Upload Logo
                                              </h5>
                                              <h6 className="card-subtitle mb-2">
                                                &nbsp;
                                              </h6>
                                              <Fileupload
                                                image_type="logo"
                                                logo_image={this.state.logo}
                                                reseller_accountno={auth.getAccount()}
                                              ></Fileupload>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-block">
                                          <div className="card card-bordered">
                                            <div className="card-inner">
                                              <h5 className="card-title">
                                                <em className="icon ni ni-camera"></em>{" "}
                                                Upload Signature
                                              </h5>
                                              <h6 className="card-subtitle mb-2">
                                                &nbsp;
                                              </h6>
                                              <Fileupload
                                                image_type="signature"
                                                logo_image={
                                                  this.state.signature_image
                                                }
                                                reseller_accountno={auth.getAccount()}
                                              ></Fileupload>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.settings_type === "smtp_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem6"
                        >
                          <div className="row g-gs">
                            {this.state.SmtpErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.SmtpErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.SmtpSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.SmtpSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.is_working === "no" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-warning">
                                    <div className="alert-text">
                                      <h4>Warning</h4>
                                      <p>
                                        Your SMTP details are not verified.
                                        Please click "Test SMTP Settings" to
                                        verify the details.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Host or ip address of your smtp server (example: smtp.company.com)"
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    SMTP Host{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_host"
                                    name="smtp_host"
                                    type="text"
                                    label="SMTP Host"
                                    value={this.state.smtp_host}
                                    onChange={this.handleChange}
                                    error={this.state.errorHostName}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Very important: What kind of encryption to use on the SMTP connection."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Security Type{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="smtp_method"
                                    name="smtp_method"
                                    select
                                    label="Security Type"
                                    value={this.state.smtp_method}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setstls1" value="tls">
                                      TLS
                                    </option>
                                    <option key="setssl1" value="ssl">
                                      SSL
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Username for authentication."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Username{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_user"
                                    name="smtp_user"
                                    type="text"
                                    label="SMTP User"
                                    value={this.state.smtp_user}
                                    onChange={this.handleChange}
                                    error={this.state.errorUser}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Password for authentication."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_password"
                                    name="smtp_password"
                                    type="text"
                                    label="SMTP Password"
                                    value={this.state.smtp_password}
                                    error={this.state.errorPassword}
                                    onChange={this.handleChange}
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The default port is 25, but some SMTP servers use a custom port (example: 587,465)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Port <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="smtp_port"
                                    name="smtp_port"
                                    select
                                    label="Port"
                                    value={this.state.smtp_port}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="port25" value="25">
                                      25
                                    </option>
                                    <option key="port465" value="465">
                                      465
                                    </option>
                                    <option key="port587" value="587">
                                      587
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The sender email address (example: account@example.com)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Email From{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="from_email"
                                    name="from_email"
                                    type="text"
                                    label="Email From"
                                    value={this.state.from_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorFromEmail}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The email address to receive the notifications (example: account@example.com)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Notifications Email{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="notifications_email"
                                    name="notifications_email"
                                    type="text"
                                    label="Notifications Email"
                                    value={this.state.notifications_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorFromEmail}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.smtp_test_button === false ? (
                                  <button
                                    type="button"
                                    className={
                                      this.state.is_working === "yes"
                                        ? "btn btn-lg btn-success"
                                        : "btn btn-lg btn-primary"
                                    }
                                    onClick={() => this.verify_smtp_settings()}
                                    style={{ marginRight: "15px" }}
                                    disabled={this.state.disabled}
                                  >
                                    {this.state.is_working === "yes" ? (
                                      <>
                                        <em class="icon ni ni-done"></em>
                                        SMTP Settings Verified
                                      </>
                                    ) : (
                                      "Test SMTP Settings"
                                    )}
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Test
                                    SMTP Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                                {this.state.smtp_reg_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() => this.add_smtp_settings()}
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.settings_type === "payment_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem7"
                        >
                          <div className="row g-gs">
                            {this.state.paymentErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.paymentErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.paymentSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.paymentSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-lg-12">
                              <h6>Bank Details</h6>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                <Editor
                                  id="bank_account_details"
                                  name="bank_account_details"
                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                  value={this.state.bank_account_details}
                                  selector="textarea#full-featured"
                                  init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    ],
                                    toolbar:
                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                  }}
                                  onEditorChange={this.handleEditorBank}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <h6>Wire Transfer</h6>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                <Editor
                                  id="wire_transfer_details"
                                  name="wire_transfer_details"
                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                  value={this.state.wire_transfer_details}
                                  selector="textarea#full-featured"
                                  init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    ],
                                    toolbar:
                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                  }}
                                  onEditorChange={this.handleEditorWire}
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <h6>ACH Details</h6>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                <Editor
                                  id="ACH_details"
                                  name="ACH_details"
                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                  value={this.state.ACH_details}
                                  selector="textarea#full-featured"
                                  init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    ],
                                    toolbar:
                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                  }}
                                  onEditorChange={this.handleEditorAch}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.payment_reg_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() => this.add_payment_settings()}
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/**** Quotation Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "quotation_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemQuotation"
                        >
                          <div className="row g-gs">
                            {this.state.quotationErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.quotationErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.quotationSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.quotationSuccessMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Preferred terms of payment (Net 30, Net 60, 50% Deposit, COD (Cash on Delivery))."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Terms of payment{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                <Select
                                    options={this.getTermOptions()}
                                    name="payment_terms"
                                    placeholder="Please select the preferred currency"
                                    value={this.state.selected_payment_terms}
                                    autoComplete="off"
                                    emptyMessage="Terms not found"
                                    onChange={this.handleChangeSearchTerms}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "40px",
                                      }),
                                      menu: (baseStyles) => ({
                                        ...baseStyles,
                                        zIndex: 9999, // Set the same or a higher z-index value
                                      }),
                                    }}
                                  />
                                  
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The currency in which you would like to receive the payment."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Currency{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.getOption()}
                                    name="selected_currency"
                                    placeholder="Please select the preferred currency"
                                    value={this.state.selected_currency}
                                    autoComplete="off"
                                    emptyMessage="Currency not found"
                                    onChange={this.handleChangeSearch}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "40px",
                                      }),
                                      menu: (baseStyles) => ({
                                        ...baseStyles,
                                        zIndex: 9999, // Set the same or a higher z-index value
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Contact Person{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="contact_person"
                                    name="contact_person"
                                    type="text"
                                    label="Provide the full name"
                                    value={this.state.contact_person}
                                    onChange={this.handleChange}
                                    error={this.state.errorcontact_person}
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Contact Email{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="contact_email"
                                    name="contact_email"
                                    type="text"
                                    label="Provide the email"
                                    value={this.state.contact_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorcontact_email}
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Specify the number of days for which this quotation will remain valid."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Validity (Days){" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="validity"
                                    name="validity"
                                    type="text"
                                    label="Quotation Validity"
                                    value={this.state.validity}
                                    onChange={this.handleChange}
                                    error={this.state.errorvalidity}
                                    inputProps={{
                                      maxLength: 4, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <h6>Quotation Notes</h6>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                <Editor
                                  id="quotation_notes"
                                  name="quotation_notes"
                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                  value={this.state.quotation_notes}
                                  selector="textarea#full-featured"
                                  init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    ],
                                    toolbar:
                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                  }}
                                  onEditorChange={this.handleEditorBank}
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group"></div>
                            </div>
                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.quotation_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() =>
                                      this.add_quotation_settings()
                                    }
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/**** Quotation Settings ****/}
                        {/**** Other Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "other_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="other_settings"
                        >
                          <div className="row g-gs">
                            {this.state.otherSettingErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.otherSettingErrorMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.otherSettingSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.otherSettingSuccessMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-lg-12">
                              <h6>Client Notes </h6>
                            </div>
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <>
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <Editor
                                      id="clientNotes"
                                      name="clientNotes"
                                      apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                      value={this.state.clientNotes}
                                      selector="textarea#full-featured"
                                      init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                          "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons",
                                        ],
                                        toolbar:
                                          "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                      }}
                                      onEditorChange={
                                        this.handleEditorClientNotes
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 text-right">
                                  <div className="form-group">
                                    {this.state.client_setting_button ===
                                    false ? (
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={() => this.saveClientNotes()}
                                        disabled={this.state.disabled}
                                      >
                                        Save Other Settings
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-lg btn-primary"
                                        style={{ marginRight: "15px" }}
                                        disabled
                                      >
                                        <em className="icon ni ni-swap"></em>{" "}
                                        Save Other Settings
                                        <div
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {/**** Other Settings ****/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Upgrades);
