import { React } from "react";
import { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { ValidateEmail, normalize } from "../../../config/utility";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { addSettings, addMsaInfo } from "../../../actions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import dateFormat, { masks } from "dateformat";
import {
  listAgents,
  regenerateLink,
  cancelLink,
  getSingleAgent,
  editAgent,
  listServicesAgents,
  assignServicesToAgent,
  sendEmail,
  listAgentSignedAgreements,
  revokeAgentSignedAgreements,
  sendUnsignedAgentEmail,
  listSubAgent,
  getAgentLogin,
  resendAgentActivation,
  getAgentSignupLink,
} from "./../../../config/api_calls";
import HelperClass from "./../../../config/helperClass";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { HelpLinksLoader } from "../../../components/ContentLoaders/newLoaders/HelpLinksLoader";
import PageTitle from "../ExtraComponents/PageTitle";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MutextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import Select from 'react-select'
import countryList from 'react-select-country-list'

const auth = new HelperClass();
class Agents extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      subAgentsData: [],
      errorMessage: "",
      errorMessageEdit: "",
      agentId: "",
      successMessage: "",
      successMessageEdit: "",
      eSuccessMessage: "",
      eErrorMessage: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      cancel_button: false,
      title: "",
      send_email: "",
      email_to: "",
      name: "",
      logo: "",
      agent_email: "",
      agent_phone: "",
      agent_company: "",
      agent_status: "",
      agent_password: "",
      agent_address: "",
      agent_address2: "",
      agent_city: "",
      agent_state: "",
      agent_zip: "",
      agent_country: null,
      sharelink_limit: "",
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      already_activated: false,
      c_password: "",
      agent_id: "",
      loginErrorMessage: "",
      login_url: "",
      serviceData: [],
      signedData: [],
      unsignedData: [],
      isEmailSending: false,
      unsigned_email_agreement_id: 0,
      revoke_reason: "",
      isRevokeReasonActive: false,
      tableLoaderListing: true,
      columnBundleServices: [],
      signup_link_agent: "",
      login_url_agent: "",
      copied1: false,
      copied2: false,

      columnServices: [
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        { name: "Company" },
        { name: "Name" },
        { name: "Email" },
        {
          name: "Phone",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setPhone(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setDomain(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Sub Agent Signup Link",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => this.subAgentLink(value, tableMeta)}
                            title="Sub Agent Signup Link"
                            disabled={
                              this.props.is_msa_signed === "No" ||
                              this.props.defaultSettings === "missing"
                                ? true
                                : false
                            }
                            className=" btn btn-icon  btn-outline-light"
                          >
                            <em className="icon ni ni-link-h"></em>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.editAgentForm(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Agent"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Agent</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.agentAgreements(value, tableMeta)
                                  }
                                  title="Signed / Un-Signed Agent Agreements"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Agreements</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.resendAgentActivation(value, tableMeta)
                                  }
                                  title="Resend activation link if account is not verified."
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-signin"></em>
                                  <span>Resend Activation</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.subAgents(value, tableMeta)
                                  }
                                  title="Sub Agents"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-user-list"></em>
                                  <span>Sub Agents</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.agentLogin(value, tableMeta)
                                  }
                                  title="Login to the Agent"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-signin"></em>
                                  <span>Dashboard</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
    // Bind the event handler functions to the current instance
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.countryOptions = countryList().getData();
  }

  async componentDidMount() {
    const servicesResponce = await listAgents(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  resendAgentActivation = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    let status = tableMeta.rowData[5];

    let status_str = status.split("__");
    let is_verified = status_str[1];

    if (is_verified === "Yes") {
      this.setState({
        already_activated: true,
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("show");
    } else {
      this.setState({
        activation_button: true,
        activation_tick: false,
        activation_cross: false,
        already_activated: false,
      });
      window.$("#modalAlertResend").modal("show");
      const kycResponce = await resendAgentActivation(
        auth.getAccount(),
        auth.getToken(),
        agent_id
      );
      //console.log("resendAgentActivation: ", kycResponce.data);
      if (
        kycResponce.data.status === 403 ||
        kycResponce.data.errors === "authentication missing" ||
        kycResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (kycResponce.data.status === 406) {
        this.setState({
          activation_button: false,
          activation_tick: false,
          activation_cross: true,
          already_activated: false,
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 404 &&
        kycResponce.data.message === "not_exists"
      ) {
        this.setState({
          activation_button: false,
          activation_tick: false,
          activation_cross: true,
          already_activated: false,
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 200 &&
        kycResponce.data.message === "success"
      ) {
        this.setState({
          activation_button: false,
          activation_tick: true,
          activation_cross: false,
          already_activated: false,
        });
      } else {
        this.setState({
          activation_button: false,
          activation_tick: false,
          activation_cross: true,
          already_activated: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("hide");
    }, 4000);
  };

  editAgentForm = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    const servicesResponce = await getSingleAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let agent_status = "0";
      if (
        servicesResponce.data.data[0][6] &&
        servicesResponce.data.data[0][6] === "active"
      ) {
        agent_status = "1";
      }
      this.setState({
        title: servicesResponce.data.data[0][0],
        name: servicesResponce.data.data[0][1],
        agent_email: servicesResponce.data.data[0][2],
        agent_phone: servicesResponce.data.data[0][3],
        agent_id: servicesResponce.data.data[0][4],
        agent_company: servicesResponce.data.data[0][5],
        agent_status: agent_status,
        sharelink_limit: servicesResponce.data.data[0][7],
        agent_address: servicesResponce.data.data[0][8],
        agent_address2: servicesResponce.data.data[0][9],
        agent_city: servicesResponce.data.data[0][10],
        agent_state: servicesResponce.data.data[0][11],
        agent_zip: servicesResponce.data.data[0][12],
        tableLoader: false,
      });
      window.$("#modalFormDetail").modal("show");
    } else {
      // window.location.replace("/error");
    }
  };

  subAgentLink = (value, tableMeta) => {
    //console.log("this.state.reg_button: ", this.state.reg_button);

    this.setState({
      copied: false,
      signup_link: tableMeta.rowData[6],
      current_id: tableMeta.rowData[7],
      errorMessage: "",
      successMessage: "",
    });
    window.$("#modalSignupLink").modal("show");
  };

  setDomain = (value, tableMeta) => {
    //console.log("tableMeta.rowData[5]: ", tableMeta.rowData[5]);
    let domain_str = tableMeta.rowData[5].split("__");
    let domain_verified = domain_str[1];
    let status = domain_str[0];

    if (status === "active") {
      if (domain_verified === "Yes") {
        return <span className="badge badge-outline-success">Active</span>;
      } else {
        return <span className="badge badge-outline-danger">Not Verified</span>;
      }
    } else {
      return <span className="badge badge-outline-danger">Inactive</span>;
    }
  };

  setPhone = (value, tableMeta) => {
    //console.log("tableMeta.rowData[5]: ", tableMeta.rowData[5]);
    if (tableMeta.rowData[4] !== "") {
      let phone = tableMeta.rowData[4].replace("+1", "");
      phone = auth.formatUsPhoneNumber(phone);
      return phone;
    } else {
      return "---";
    }
  };

  agentLogin = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    this.setState({
      loginErrorMessage: "",
      login_url: "",
    });
    window.$("#modalDefaultLogin").modal("show");
    const servicesResponce = await getAgentLogin(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("agentLogin.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "inactive"
    ) {
      this.setState({
        loginErrorMessage: "Account is not active.",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "notVerified"
    ) {
      this.setState({
        loginErrorMessage:
          "Account is not verifed. Please check your email and click verify link.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        login_url: servicesResponce.data.login_url,
      });

      window.open(servicesResponce.data.login_url, "_blank");
      window.$("#modalDefaultLogin").modal("hide");
    } else {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    }
  };

  show_email = () => {
    this.setState({
      send_email: "1",
    });
  };

  send_email = async () => {
    let { email_to } = this.state;
    this.setState({
      eSuccessMessage: "",
      eErrorMessage: "",
    });
    let is_validated = true;
    if (this.state.email_to === "") {
      is_validated = false;
    }
    if (is_validated) {
      let apiResponce = await sendEmail(
        auth.getAccount(),
        auth.getToken(),
        this.state.email_to,
        this.state.signup_link,
        this.state.current_id
      );
      console.log("apiResponce.send_email: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          eSuccessMessage: "Email sent successfully.",
          eErrorMessage: "",
          disabled: false,
        });
      } else {
        this.setState({
          eErrorMessage: "Something went wrong,try again later.",
          disabled: false,
          eSuccessMessage: "",
        });
      }
    } else {
      this.setState({
        eSuccessMessage: "",
        eErrorMessage: "Please enter the email address.",
      });
    }
    setTimeout(() => {
      this.setState({
        eSuccessMessage: "",
        eErrorMessage: "",
      });
    }, 4000);
  };

  regenerate_link = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await regenerateLink(
      auth.getAccount(),
      auth.getToken(),
      this.state.current_id
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link generated successfully.",
        reg_button: false,
      });
      //this.componentDidMount();
    } else {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      // window.location.replace("/error");
    }
  };

  cancel_link = async () => {
    this.setState({
      cancel_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await cancelLink(
      auth.getAccount(),
      auth.getToken(),
      this.state.current_id
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link removed successfully.",
        cancel_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      // window.location.replace("/error");
    }
  };

  showServices = async (value, tableMeta) => {
    const servicesResponce = await listServicesAgents(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("showServices.data: ", tableMeta);
    this.setState({
      agent_id: tableMeta.rowData[7],
      company_title: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
      errorMessage: "",
      successMessage: "",
      emptyService: 0,
    });

    window.$("#modalFormDetailService").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "Services/rates are not added to your account. Please add proper services/rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        errorMessage: "",
      });
      this.renderServices();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    }
  };

  agentAgreements = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    const servicesResponce = await listAgentSignedAgreements(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    //console.log("showAgreements.data: ", servicesResponce.data);
    this.setState({
      errorMessage: "",
      successMessage: "",
      agent_id: agent_id,
      revoke_reason: "",
      isRevokeReasonActive: false,
      company_title: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
    });

    window.$("#modalFormAgentAgreements").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage: "There is some error while getting the agreements.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the agreements.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signedData: servicesResponce.data.signedArray,
        unsignedData: servicesResponce.data.unsignedArray,
        errorMessage: "",
      });
      this.renderServices();
    } else {
      this.setState({
        errorMessage: "There is some error while getting the agreements.",
      });
      //window.location.replace("/error");
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[4];
    //console.log("domain_str: ", domain_str);
    if (domain_str === "active") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done" title="Active"></em> Active
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Inactive
          </span>
        </div>
      );
    }
  };

  subAgents = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    const servicesResponce = await listSubAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    //console.log("subAgents.data: ", servicesResponce.data);
    this.setState({
      errorMessage: "",
      successMessage: "",
      agent_id: agent_id,
    });

    window.$("#modalFormSubAgents").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "Services/rates are not added to your account. Please add proper services/rates.",
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There are no sub agents.",
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        subAgentsData: servicesResponce.data.data,
        columnBundleServices: [
          { name: "Date Added" },
          { name: "Title" },
          { name: "Name" },
          { name: "Email" },
          {
            name: "Status",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.setStatus(value, tableMeta)}</div>;
              },
            },
          },
        ],
        errorMessage: "",
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  };

  renderServices = () => {
    let service_data = this.state.serviceData;
    //console.log("Render Data: ", service_data);
    let didArray = [];
    let tfnArray = [];
    let termArray = [];
    let smsArray = [];
    let ivrArray = [];
    let liveagentArray = [];
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][5] == "did") {
        let insideArray1 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        didArray.push(insideArray1);
      }

      if (service_data[i][5] == "tfn") {
        let insideArray2 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        tfnArray.push(insideArray2);
      }
      if (service_data[i][5] == "term") {
        let insideArray3 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        termArray.push(insideArray3);
      }
      if (service_data[i][5] == "ivr") {
        let insideArray5 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        ivrArray.push(insideArray5);
      }
      if (service_data[i][5] == "live_agent") {
        let insideArray6 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        liveagentArray.push(insideArray6);
      }
      if (service_data[i][5] == "sms") {
        let insideArray4 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
          service_data[i][10],
          service_data[i][11],
        ];
        smsArray.push(insideArray4);
      }
    }
    //console.log("didArray: ", didArray);
    //console.log("tfnArray: ", tfnArray);
    //console.log("termArray: ", termArray);
    //console.log("smsArray: ", smsArray);
    //console.log("ivrArray: ", ivrArray);
    // console.log("liveagentArray: ", liveagentArray);
    this.setState({
      didArray: didArray,
      tfnArray: tfnArray,
      termArray: termArray,
      smsArray: smsArray,
      ivrArray: ivrArray,
      liveagentArray: liveagentArray,
    });
  };

  formSaveServices = async () => {
    let {
      did_service,
      tfn_service,
      term_service,
      sms_service,
      ivr_service,
      liveagent_service,
    } = this.state;
    await this.setPriceStates(
      did_service,
      tfn_service,
      term_service,
      sms_service,
      ivr_service,
      liveagent_service
    );
    this.setState({ errorMessage: "" });

    this.setState({
      errorMessage: "",
      disabled: true,
    });

    const saveResponce = await assignServicesToAgent(
      auth.getAccount(),
      auth.getToken(),
      did_service,
      tfn_service,
      term_service,
      this.state.agent_id,
      this.state.nrc_did,
      this.state.mrc_did,
      this.state.rate_did,
      this.state.nrc_tfn,
      this.state.mrc_tfn,
      this.state.rate_tfn,
      this.state.rate_term,
      sms_service,
      this.state.rate_smsin,
      this.state.rate_smsout,
      ivr_service,
      liveagent_service,
      this.state.rate_ivr,
      this.state.rate_liveagent
    );
    //console.log("Assign Result: ", saveResponce.data.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Services assigned successfully.",
        disabled: false,
      });

      $("#viewBtn").show();
      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDetail").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning the services.",
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
  };

  formUpdateSubmit = async () => {
    let {
      title,
      name,
      agent_email,
      agent_password,
      c_password,
      agent_phone,
      agent_company,
      agent_status,
      sharelink_limit,
      agent_address,
      agent_address2,
      agent_city,
      agent_state,
      agent_zip,
      agent_country,
    } = this.state;

    let is_validated = true;
    if (this.state.name === "") {
      this.setState({
        errorCompName: "error",
        errorMessageEdit: "Agent Name is required.",
      });
      is_validated = false;
    } else if (this.state.agent_company === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessageEdit: "Company name is required.",
      });
      is_validated = false;
    } else if (this.state.sharelink_limit === "") {
      this.setState({
        //errorSharelink: "error",
        errorMessageEdit: "Share link limit is required.",
      });
      is_validated = false;
    } else if (this.state.agent_phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } /*else if (normalize(this.state.agent_phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "Invalid phone format.",
      });
      is_validated = false;
    }*/ else if (ValidateEmail(this.state.agent_email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessageEdit: "Invalid email format.",
      });
      is_validated = false;
    } else if (this.state.agent_password !== "") {
      if (this.state.agent_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (this.state.c_password === "") {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (this.state.c_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (
        this.state.agent_password.trim() != this.state.c_password.trim()
      ) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessageEdit: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    } else if (this.state.agent_country === "") {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.agent_address === "") {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.agent_city === "") {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "City is required.",
      });
      is_validated = false;
    } else if (this.state.agent_state === "") {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "State is required.",
      });
      is_validated = false;
    } else if (this.state.agent_zip === "") {
      this.setState({
        errorPhone: "error",
        errorMessageEdit: "Zip/postal code is required.",
      });
      is_validated = false;
    }
    if (is_validated) {
      let apiResponce = await editAgent(
        auth.getAccount(),
        auth.getToken(),
        title,
        name,
        agent_email,
        agent_password,
        agent_phone,
        this.state.agent_id,
        agent_company,
        agent_status,
        sharelink_limit,
        agent_address,
        agent_address2,
        agent_city,
        agent_state,
        agent_zip,
        agent_country,
      );
      //console.log("Edit Agent RES: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessageEdit: "agent updated successfully.",
          errorMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(async function () {
          //window.$("#modalFormDetail").modal("hide");
          window.location.replace("/agents");
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleRevokeValueChange = (event) => {
    const { target } = event;
    const value = target.value;

    this.setState({
      revoke_reason: value,
    });
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label
    this.setState({
      agent_country: country,
    });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  downloadPdf = async (d_f_name, title) => {
    if (d_f_name) {
      var url =
        process.env.REACT_APP_AGENT_PORTAL_URL +
        "files_data/agreements/" +
        d_f_name;
      //console.log("File URL: ", url);
      var pdf_name = title.replace(/[^a-zA-Z ]/g, "");
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = pdf_name + ".pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  revokeAgreement = async (agreement_id, agreement_accountno) => {
    this.setState({
      isRevokeReasonActive: true,
      revoke_reason: "",
      errorMessage: "",
      successMessage: "",
      revokeAgreementData: {
        agreement_id: agreement_id,
        agreement_accountno: agreement_accountno,
      },
    });
    //console.log("agreement_accountno: ", agreement_accountno);
  };

  sendEmailUnsignedAgreement = async (
    agreement_id,
    agreement_accountno,
    agent_id
  ) => {
    this.setState({
      successMessage: "",
      errorMessage: "",
      isEmailSending: true,
      unsigned_email_agreement_id: agreement_id,
    });

    const servicesResponce = await sendUnsignedAgentEmail(
      auth.getAccount(),
      auth.getToken(),
      agent_id,
      agreement_id,
      agreement_accountno
    );

    this.setState({
      isEmailSending: false,
    });

    console.log("sendEmailUnsignedAgreement.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "There is an error while sending an email. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is an error while sending an email. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessage: "Email Sent Successfully",
      });
      setTimeout(function () {
        window.$("#modalFormAgentAgreements").modal("hide");
      }, 4000);
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  handleAgentAgreementRevokeSubmit = async (
    agreement_id,
    agreement_accountno,
    agent_id,
    revoke_reason
  ) => {
    //console.log('handleAgentAgreementRevokeSubmit',agent_id, agreement_id, revoke_reason, agreement_accountno);
    let is_validated = true;
    if (revoke_reason === "") {
      is_validated = false;
      this.setState({
        errorCompName: "error",
        errorMessage: "Revoke Reason is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        successMessage: "",
        errorMessage: "",
        disabled: true,
      });

      const servicesResponce = await revokeAgentSignedAgreements(
        auth.getAccount(),
        auth.getToken(),
        agent_id,
        agreement_id,
        agreement_accountno,
        revoke_reason
      );
      console.log(
        "handleAgentAgreementRevokeSubmit.data: ",
        servicesResponce.data
      );
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          successMessage: "Agreement is revoked successfully",
          revoke_reason: "",
          isRevokeReasonActive: false,
        });
        setTimeout(function () {
          window.$("#modalFormAgentAgreements").modal("hide");
        }, 4000);
      } else {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handlePhoneNumberChange(value) {
    this.setState({
      agent_phone: value,
    });
  }

  getSignUpLink = async () => {
    this.setState({
      tableLoader: true,
    });
    window.$("#modalgetSignupLink").modal("show");
    const servicesResponce = await getAgentSignupLink(
      auth.getAccount(),
      auth.getToken()
    );

    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableLoader: true,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link_agent: servicesResponce.data.data,
        login_url_agent: servicesResponce.data.login_url,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableLoader: true,
      });
      // window.location.replace("/error");
    }
  };

  render() {
    return (
      <div className="nk-content " id="Agents_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Agents / Channel Partners"
                        icon="icon ni ni-user-list"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() => this.getSignUpLink()}
                              className="btn btn-info"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-signin"></em>
                              &nbsp;Agent Signup Link
                            </Link>
                          </li>
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() =>
                                this.addMenuHistory("/agents/addagent")
                              }
                              className="btn btn-primary"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Agent
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** Login Modal Start **/}
              <div className="modal fade" tabindex="-1" id="modalgetSignupLink">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em
                          className="icon ni ni-user-list"
                          style={{ marginRight: "15px" }}
                        ></em>{" "}
                        Agent Signup Link
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        <div className="col-xxl-12">
                          {this.state.tableLoader === true ? (
                            HelpLinksLoader()
                          ) : (
                            <>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-user"></em>{" "}
                                      Agent / Channel Partner Signup Link
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    <div className="example-alert">
                                      <div className="alert alert-light alert-icon">
                                        <em className="icon ni ni-alert-circle"></em>{" "}
                                        <strong>NOTE: </strong> Anyone with this
                                        link will be able to create a super
                                        agent for this reseller. Be very careful
                                        where and how you send this! It is also
                                        a good idea to make sure you have any
                                        supporting documents and contracts in
                                        place before you allow the reseller to
                                        have its first super agent.{" "}
                                      </div>
                                    </div>

                                    <div
                                      className="caption-text"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-light">
                                          <em className="icon ni ni-link"></em>{" "}
                                          {this.state.signup_link_agent &&
                                          this.state.signup_link_agent !==
                                            "" ? (
                                            <>{this.state.signup_link_agent}</>
                                          ) : (
                                            <>
                                              Agent signup link is not
                                              available. Please create a new one
                                              by clicking on "Regenerate"
                                              button.
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      {this.state.signup_link_agent &&
                                      this.state.signup_link_agent !== "" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                          }}
                                        >
                                          <CopyToClipboard
                                            text={this.state.signup_link_agent}
                                            onCopy={() =>
                                              this.setState({ copied1: true })
                                            }
                                          >
                                            <button className="btn btn-success">
                                              <em className="icon ni ni-copy"></em>{" "}
                                              Copy to clipboard{" "}
                                              {this.state.copied1 ? (
                                                <span
                                                  style={{ color: "white" }}
                                                >
                                                  <em className="icon ni ni-done"></em>
                                                </span>
                                              ) : null}
                                            </button>
                                          </CopyToClipboard>
                                          <a
                                            href={this.state.signup_link_agent}
                                            target="_blank"
                                            className="btn btn-info mx-2"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              cursor: "pointer",
                                              color: "#fff",
                                            }}
                                          >
                                            <em className="icon ni ni-external"></em>
                                            <span>Redirect to url</span>
                                          </a>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-signin"></em>{" "}
                                      Agent Login URL
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    <div className="example-alert">
                                      <div
                                        className="alert alert-light alert-icon"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        <em className="icon ni ni-link"></em>{" "}
                                        {this.state.login_url_agent &&
                                        this.state.login_url_agent !== "" ? (
                                          <>{this.state.login_url_agent}</>
                                        ) : (
                                          <>
                                            Agent login link is not available.
                                            Please create a new one by clicking
                                            on "Regenerate" button.
                                          </>
                                        )}
                                      </div>

                                      {this.state.login_url_agent &&
                                      this.state.login_url_agent !== "" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                          }}
                                        >
                                          <CopyToClipboard
                                            text={this.state.login_url_agent}
                                            onCopy={() =>
                                              this.setState({ copied2: true })
                                            }
                                          >
                                            <button className="btn btn-success">
                                              <em className="icon ni ni-copy"></em>{" "}
                                              Copy to clipboard{" "}
                                              {this.state.copied2 ? (
                                                <span
                                                  style={{ color: "white" }}
                                                >
                                                  <em className="icon ni ni-done"></em>
                                                </span>
                                              ) : null}
                                            </button>
                                          </CopyToClipboard>
                                          <a
                                            href={this.state.login_url_agent}
                                            target="_blank"
                                            className="btn btn-info mx-2"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              cursor: "pointer",
                                              color: "#fff",
                                            }}
                                          >
                                            <em className="icon ni ni-external"></em>
                                            <span>Redirect to url</span>
                                          </a>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** Login Modal End **/}
              {/** Login Modal Start **/}
              <div className="modal fade" tabindex="-1" id="modalDefaultLogin">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em
                          className="icon ni ni-user-list"
                          style={{ marginRight: "15px" }}
                        ></em>{" "}
                        Login to the Agent
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.loginErrorMessage === "" ? (
                        <div className="alert alert-pro alert-primary">
                          <div className="alert-text">
                            <h6>Connecting.....</h6>
                            <p>
                              Please wait while we are connecting to the agent
                              Login. You will be redirected to the Agent
                              dashboard after the authentication.
                            </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.loginErrorMessage !== "" ? (
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h6>Login Error</h6>
                            <p>{this.state.loginErrorMessage}</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/** Login Modal End **/}
              {/* DETAIL MODAL START */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalSignupLink"
              >
                <div className="modal-dialog  modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-link-alt"></em> Sub Agent
                          Signup Link
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ marginBottom: "20px" }}>
                            <div className="example-alert">
                              <div className="alert alert-light alert-icon">
                                <em className="icon ni ni-alert-circle"></em>{" "}
                                <strong>NOTE: </strong> Anyone with this link
                                will be able to create a sub agent for this
                                agent. Be very careful where and how you send
                                this! It is also a good idea to make sure you
                                have any supporting documents and contracts in
                                place before you allow the agent to have its
                                first sub agent.{" "}
                              </div>
                            </div>
                          </div>
                          <div
                            className="caption-text"
                            style={{ marginBottom: "15px" }}
                          >
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-light">
                                {this.state.signup_link}
                              </div>
                            </div>
                            {this.state.reg_button === false ? (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                onClick={() => this.regenerate_link()}
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                disabled
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            )}

                            {this.state.cancel_button === false ? (
                              <button
                                className="btn btn-danger"
                                style={{ marginRight: "15px" }}
                                onClick={() => this.cancel_link()}
                              >
                                <em className="icon ni ni-cross-round"></em>{" "}
                                Cancel
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger"
                                style={{ marginRight: "15px" }}
                                disabled
                              >
                                <em className="icon ni ni-cross-round"></em>{" "}
                                Cancel
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            )}

                            <CopyToClipboard
                              text={this.state.signup_link}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <button className="btn btn-success">
                                <em className="icon ni ni-copy"></em> Copy to
                                clipboard{" "}
                                {this.state.copied ? (
                                  <span style={{ color: "white" }}>
                                    <em className="icon ni ni-done"></em>
                                  </span>
                                ) : null}
                              </button>
                            </CopyToClipboard>
                            <button
                              className="btn btn-warning"
                              style={{ marginLeft: "15px" }}
                              onClick={() => this.show_email()}
                            >
                              <em className="icon ni ni-emails"></em> Email Link
                            </button>
                          </div>
                          {this.state.send_email === "1" ? (
                            <div
                              className="row g-4 mt-4"
                              style={{ marginTop: "25px" }}
                            >
                              {this.state.eErrorMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.eErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.eSuccessMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.eSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-7">
                                <MutextField
                                  required
                                  id="email_to"
                                  name="email_to"
                                  type="email"
                                  label="Enter Email Address"
                                  defaultValue={this.state.email_to}
                                  inputProps={{ maxLength: 100 }}
                                  onChange={this.handleChange}
                                  helperText="Enter your Email Address here."
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                              <div className="col-md-5">
                                <button
                                  className="btn btn-lg btn-primary"
                                  onClick={() => this.send_email()}
                                  disabled={this.state.disabled}
                                >
                                  <em className="icon ni ni-emails"></em> Send
                                  Email Link
                                </button>
                                <button
                                  className="btn btn-lg btn-light"
                                  style={{ marginLeft: "5px" }}
                                  onClick={() =>
                                    this.setState({ send_email: "" })
                                  }
                                >
                                  <em className="icon ni ni-minimize-alt"></em>
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Agent</h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <form method="post" encType="multipart/form-data">
                          <div className="row g-4">
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="name"
                                name="name"
                                type="text"
                                label="Agent Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.name}
                                onChange={this.handleChange}
                                helperText="Enter the agent name."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="title"
                                name="title"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Agent Title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                helperText="Enter the title here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_company"
                                name="agent_company"
                                type="text"
                                inputProps={{ maxLength: 100 }}
                                label="Company Name"
                                value={this.state.agent_company}
                                onChange={this.handleChange}
                                helperText="Enter the company name here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_email"
                                name="agent_email"
                                type="email"
                                label="Email Address"
                                value={this.state.agent_email}
                                onChange={this.handleChange}
                                helperText="Enter your email address here. (Read Only)"
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <PhoneInput
                                required
                                defaultCountry={"us"}
                                inputProps={{
                                  name: "agent_phone",
                                  id: "agent_phone",
                                  maxLength: 20,
                                }}
                                value={this.state.agent_phone}
                                onChange={(value) =>
                                  this.handleChange({
                                    target: { name: "agent_phone", value },
                                  })
                                }
                                helperText="Enter the phone number here."
                                label="Phone Number"
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="agent_status"
                                name="agent_status"
                                select
                                label="Status"
                                value={this.state.agent_status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="e.g Active or Inactive"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="status1" value="1">
                                  Active
                                </option>
                                <option key="status0" value="0">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_address"
                                name="agent_address"
                                type="text"
                                label="Address"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.agent_address}
                                onChange={this.handleChange}
                                helperText="Please provide your complete address, excluding city, state, and zip."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="agent_address2"
                                name="agent_address2"
                                type="text"
                                label="Address 2"
                                value={this.state.agent_address2}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                helperText="If needed, enter additional address details, such as apartment number, suite, or unit."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <Select id="agent_country" className="customCountrySelect-addAgent" name="agent_country" label="country" options={this.countryOptions} value={this.state.agent_country} onChange={this.handleChangeCountry} styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: 9,
                                }),
                              }}
                              placeholder="Select Country"
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_city"
                                name="agent_city"
                                type="text"
                                label="City"
                                value={this.state.agent_city}
                                inputProps={{ maxLength: 32 }}
                                onChange={this.handleChange}
                                helperText=""
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_state"
                                name="agent_state"
                                type="text"
                                label="State"
                                value={this.state.agent_state}
                                inputProps={{ maxLength: 3 }}
                                onChange={this.handleChange}
                                helperText=""
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_zip"
                                name="agent_zip"
                                type="text"
                                label="Zip / Postal Code"
                                value={this.state.agent_zip}
                                inputProps={{ maxLength: 9 }}
                                onChange={this.handleChange}
                                helperText=""
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_password"
                                name="agent_password"
                                type="password"
                                label="Paasword"
                                value={this.state.agent_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter your password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="c_password"
                                name="c_password"
                                type="password"
                                label="Confirm Password"
                                value={this.state.c_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter the confirm password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="sharelink_limit"
                                name="sharelink_limit"
                                type="text"
                                label="Sharelink Limit"
                                inputProps={{ maxLength: 2 }}
                                value={this.state.sharelink_limit}
                                onChange={this.handleChange}
                                helperText="Number of share links agent can add."
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div style={{ clear: "both" }}></div>
                            <hr />
                            <div className="form-group text-right col-md-12">
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.formUpdateSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalFormDetailService"
              >
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-add-c"></em> Assign
                          Services To Agent{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg text-left">
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            <div
                              id="accordion-2"
                              className="accordion accordion-s3"
                            >
                              {this.state.didArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head"
                                    data-toggle="collapse"
                                    data-target="#accordion-did"
                                  >
                                    <h6 className="title">DID Services</h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse show"
                                    id="accordion-did"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      <div className="nk-block">
                                        <ul className="sp-pdl-list">
                                          {this.state.didArray.map(
                                            (didservices) => (
                                              <li className="sp-pdl-item">
                                                <div className="sp-pdl-desc">
                                                  <div className="sp-pdl-img">
                                                    <FormControlLabel
                                                      control={
                                                        <Radio
                                                          checked={
                                                            didservices[0] ==
                                                            this.state
                                                              .did_service
                                                          }
                                                          onChange={
                                                            this
                                                              .handleChangeRadio
                                                          }
                                                          name="did_service"
                                                          id={didservices[0]}
                                                          value={
                                                            this.state
                                                              .did_service
                                                          }
                                                          color="primary"
                                                        />
                                                      }
                                                      label=""
                                                    />
                                                  </div>
                                                  <div className="sp-pdl-info">
                                                    <h6 className="sp-pdl-title">
                                                      <span className="sp-pdl-name">
                                                        {didservices[1]}
                                                      </span>{" "}
                                                      {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                    </h6>
                                                    <div className="sp-pdl-meta">
                                                      <span className="version">
                                                        <span className="text-soft">
                                                          NRC:{" "}
                                                        </span>{" "}
                                                        <span>
                                                          ${didservices[3]}
                                                        </span>
                                                      </span>
                                                      <span className="release">
                                                        <span className="text-soft">
                                                          MRC:{" "}
                                                        </span>{" "}
                                                        <span>
                                                          ${didservices[4]}
                                                        </span>
                                                      </span>

                                                      <span className="release">
                                                        <span className="text-soft">
                                                          Rate Per Min:{" "}
                                                        </span>{" "}
                                                        <span>
                                                          ${didservices[6]}
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.tfnArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-tfn"
                                  >
                                    <h6 className="title">TFN Services</h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-tfn"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.tfnArray.map(
                                        (tfnservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={this.state.tfn_service}
                                                  name="tfn_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {tfnservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      NRC:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${tfnservices[3]}
                                                    </span>
                                                  </span>
                                                  <span className="release">
                                                    <span className="text-soft">
                                                      MRC:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${tfnservices[4]}
                                                    </span>
                                                  </span>

                                                  <span className="release">
                                                    <span className="text-soft">
                                                      Rate Per Min:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${tfnservices[6]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.termArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-term"
                                  >
                                    <h6 className="title">
                                      Termination Services
                                    </h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-term"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.termArray.map(
                                        (termservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={
                                                    this.state.term_service
                                                  }
                                                  name="term_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {termservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      Rate Per Min:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${termservices[6]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.ivrArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-ivr"
                                  >
                                    <h6 className="title">IVR Services</h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-ivr"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.ivrArray.map(
                                        (ivrservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={this.state.ivr_service}
                                                  name="ivr_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {ivrservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      Rate Per Min:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${ivrservices[6]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.liveagentArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-liveagent"
                                  >
                                    <h6 className="title">
                                      Live Agent Services
                                    </h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-liveagent"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.liveagentArray.map(
                                        (liveagentservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={this.state.ivr_service}
                                                  name="ivr_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {liveagentservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      Rate Per Min:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${liveagentservices[6]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.smsArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-sms"
                                  >
                                    <h6 className="title">SMS Services</h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-sms"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.smsArray.map(
                                        (smsservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={this.state.tfn_service}
                                                  name="tfn_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {smsservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      SMS In:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${smsservices[10]}
                                                    </span>
                                                  </span>
                                                  <span className="release">
                                                    <span className="text-soft">
                                                      SMS Out:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${smsservices[11]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            {/*{this.state.didArray !== "" ? (
                              <div className="col-md-12 col-lg-12 col-xxl-12">
                                <MutextField
                                  id="did_service"
                                  name="did_service"
                                  select
                                  label="Select DID Service"
                                  value={this.state.did_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the DID service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.didArray.map((didservices) => (
                                    <option value={didservices[0]}>
                                      {didservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.tfnArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="tfn_service"
                                  name="tfn_service"
                                  select
                                  label="Select TFN Service"
                                  value={this.state.tfn_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the TFN service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.tfnArray.map((tfnservices) => (
                                    <option value={tfnservices[0]}>
                                      {tfnservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.termArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="term_service"
                                  name="term_service"
                                  select
                                  label="Select Termination Rate Service"
                                  value={this.state.term_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the termination rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.termArray.map((termservices) => (
                                    <option value={termservices[0]}>
                                      {termservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.ivrArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="ivr_service"
                                  name="ivr_service"
                                  select
                                  label="Select IVR Rate Service"
                                  value={this.state.ivr_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the IVR rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.ivrArray.map((ivrservices) => (
                                    <option value={ivrservices[0]}>
                                      {ivrservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.liveagentArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="liveagent_service"
                                  name="liveagent_service"
                                  select
                                  label="Select Live Agent Rate Service"
                                  value={this.state.liveagent_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the Live Agent rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.liveagentArray.map(
                                    (liveagentservices) => (
                                      <option value={liveagentservices[0]}>
                                        {liveagentservices[1]}
                                      </option>
                                    )
                                  )}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.smsArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="sms_service"
                                  name="sms_service"
                                  select
                                  label="Select SMS Rate Service"
                                  value={this.state.sms_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the SMS rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.smsArray.map((smsservices) => (
                                    <option value={smsservices[0]}>
                                      {smsservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}*/}
                          </div>
                        </div>
                        <div className="nk-modal-action  text-right">
                          {this.state.emptyService == 0 ? (
                            <a
                              style={{ cursor: "pointer" }}
                              className="btn btn-lg btn-mw btn-primary "
                              onClick={() => {
                                this.formSaveServices();
                              }}
                              disabled={this.state.disabled}
                            >
                              Assign Services
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}

              {/* DETAIL MODAL START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalFormAgentAgreements"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-file-docs"></em>Agreements{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#tabItemSigned"
                                >
                                  <em className="icon ni ni-check-circle-cut"></em>
                                  <span>Signed Agreements</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#tabItemUnsigned"
                                >
                                  <em className="icon ni ni-na"></em>
                                  <span>Unsigned Agreements</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                className="tab-pane active"
                                id="tabItemSigned"
                              >
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.isRevokeReasonActive ? (
                                  <div id="revokeReasonItems">
                                    <div className="tb-odr-id">
                                      Reason to revoke
                                    </div>
                                    <div className="card card-bordered card-preview mt-1">
                                      <TextareaAutosize
                                        maxrows={4}
                                        name="revoke_reason"
                                        id="revoke_reason"
                                        aria-label=""
                                        placeholder=""
                                        value={this.state.revoke_reason}
                                        helperText="Reason to revoke this agreement"
                                        onChange={this.handleRevokeValueChange}
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          borderColor: "rgba(0, 0, 0, 0.125)",
                                          borderRadius: "4px",
                                        }}
                                        variant="outlined"
                                      />
                                    </div>
                                    <div className="form-group text-right mt-2 mb-2">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          this.handleAgentAgreementRevokeSubmit(
                                            this.state.revokeAgreementData
                                              .agreement_id,
                                            this.state.revokeAgreementData
                                              .agreement_accountno,
                                            this.state.agent_id,
                                            this.state.revoke_reason
                                          );
                                        }}
                                        disabled={this.state.disabled}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="card card-bordered card-preview">
                                  <table className="table table-orders">
                                    <thead className="tb-odr-head">
                                      <tr className="tb-odr-item">
                                        <th>
                                          <span className="tb-odr-id">
                                            Agreement Name
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-date d-none d-md-inline-block">
                                            Signed Date
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-total">
                                            Status
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-status d-none d-md-inline-block">
                                            Download
                                          </span>
                                        </th>
                                        <th className="tb-odr-action">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tb-odr-body">
                                      {this.state.signedData ? (
                                        <>
                                          {this.state.signedData.map(
                                            (signed, index) => (
                                              <tr
                                                className="tb-odr-item"
                                                key={`sign${index}`}
                                              >
                                                <td>
                                                  <span className="tb-odr-id">
                                                    {signed.agreement_title}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="tb-odr-date">
                                                    {signed.signed_date}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="tb-odr-status">
                                                    <span className="badge badge-outline-success">
                                                      Signed
                                                    </span>
                                                  </span>
                                                </td>
                                                <td>
                                                  <a
                                                    onClick={() =>
                                                      this.downloadPdf(
                                                        signed.pdf_path,
                                                        signed.agreement_title
                                                      )
                                                    }
                                                    title="Download Agreement"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <span className="badge badge-outline-primary">
                                                      <em className="icon ni ni-download"></em>
                                                    </span>
                                                  </a>
                                                </td>
                                                <td className="tb-odr-action">
                                                  <div className="dropdown">
                                                    <a
                                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                      data-toggle="dropdown"
                                                      data-offset="-8,0"
                                                    >
                                                      <em className="icon ni ni-more-h"></em>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                      <ul className="link-list-plain">
                                                        <li>
                                                          <a
                                                            href="#"
                                                            onClick={() =>
                                                              this.revokeAgreement(
                                                                signed.agreement_id,
                                                                signed.agreement_accountno
                                                              )
                                                            }
                                                            className="text-primary"
                                                          >
                                                            Revoke
                                                          </a>
                                                        </li>
                                                        {/*<li>
                                                          <a
                                                            href="#"
                                                            className="text-danger"
                                                          >
                                                            Send Email
                                                          </a>
                                            </li>*/}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      ) : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="tab-pane" id="tabItemUnsigned">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="card card-bordered card-preview">
                                  <table className="table table-orders">
                                    <thead className="tb-odr-head">
                                      <tr className="tb-odr-item">
                                        <th>
                                          <span className="tb-odr-id">
                                            Agreement Name
                                          </span>
                                        </th>

                                        <th>
                                          <span className="tb-odr-total">
                                            Status
                                          </span>
                                        </th>

                                        <th className="tb-odr-action">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tb-odr-body">
                                      {this.state.unsignedData ? (
                                        <>
                                          {this.state.unsignedData.map(
                                            (unsigned, index) => (
                                              <tr
                                                className="tb-odr-item"
                                                key={`unsign${index}`}
                                              >
                                                <td>
                                                  <span className="tb-odr-id">
                                                    {unsigned.agreement_title}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="tb-odr-status">
                                                    <span className="badge badge-outline-warning">
                                                      Unsigned
                                                    </span>
                                                  </span>
                                                </td>

                                                <td className="tb-odr-action">
                                                  {this.state.isEmailSending &&
                                                  this.state
                                                    .unsigned_email_agreement_id ===
                                                    unsigned.agreement_id ? (
                                                    <div
                                                      className="spinner-border spinner-border-sm"
                                                      role="status"
                                                    >
                                                      <span className="sr-only">
                                                        Loading...
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div className="dropdown">
                                                      <a
                                                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                        data-toggle="dropdown"
                                                        data-offset="-8,0"
                                                      >
                                                        <em className="icon ni ni-more-h"></em>
                                                      </a>
                                                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                        <ul className="link-list-plain">
                                                          {/*<li>
                                                          <a
                                                            href="#"
                                                            className="text-primary"
                                                          >
                                                            Revoke
                                                          </a>
                                            </li>*/}
                                                          <li>
                                                            <a
                                                              href="#"
                                                              className="text-danger"
                                                              onClick={() =>
                                                                this.sendEmailUnsignedAgreement(
                                                                  unsigned.agreement_id,
                                                                  unsigned.agreement_accountno,
                                                                  this.state
                                                                    .agent_id
                                                                )
                                                              }
                                                            >
                                                              Send Email
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      ) : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-modal-action  text-right">
                          {this.state.emptyService == 0 ? (
                            <a
                              href="#"
                              className="btn btn-lg btn-mw btn-primary "
                              onClick={() => {
                                this.formSaveServices();
                              }}
                              disabled={this.state.disabled}
                            >
                              Assign Services
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Agreement MODAL End */}

              {/* SUB AGENT MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormSubAgents">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-file-docs"></em> Sub Agents
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTable
                              columns={this.state.columnBundleServices}
                              tableData={this.state.subAgentsData}
                              title="All Sub Agents"
                              style={{ border: "0px" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SUB AGENT MODAL END */}

              {/* PAGE TITLE END */}
              <div className="nk-block">
                {/* Table 5th Col Start */}
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Agents"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                </div>
                {/* Table 5th Col End */}
              </div>
              {/* Activation Modal */}
              <div className="modal fade" tabIndex="-1" id="modalAlertResend">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a href="#" className="close" data-dismiss="modal">
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        {this.state.activation_button === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                            <h4 className="nk-modal-title">Sending!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Please wait while we are processing your request
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_tick === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Congratulations!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Activation email has been sent successfully.
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_cross === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 className="nk-modal-title">Oops!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                There is some error while sending activation
                                email. Please try again later
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.already_activated === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Congratulations!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Your account is already activated.
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="modal-footer bg-lighter">
                      <div className="text-center w-100">
                        <a
                          href="#"
                          className="btn btn-lg btn-mw btn-primary"
                          data-dismiss="modal"
                        >
                          Close
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Activation Modal */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agents);
