import axios from "axios";

import $ from "jquery";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { axiosConfig } from "./utility";

export const getAgentSignup = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/get_super_agent_signup",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const regenerateAgentLink = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "resellers/regenerate_super_agent_link",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const cancelAgentLink = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/cancel_super_agent_link",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSmtpSettings = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/get_smtp_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addSmtpSettings = async (
  accountno,
  token,
  smtp_host,
  smtp_user,
  smtp_password,
  smtp_method,
  smtp_port,
  from_email,
  notifications_email
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/add_smtp_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            smtp_host: smtp_host,
            smtp_user: smtp_user,
            smtp_password: smtp_password,
            smtp_method: smtp_method,
            smtp_port: smtp_port,
            from_email: from_email,
            notifications_email: notifications_email,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const verifySmtpSettings = async (
  accountno,
  token,
  smtp_host,
  smtp_user,
  smtp_password,
  smtp_method,
  smtp_port,
  from_email
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "smtp_tester/verify_smtp",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            smtp_host: smtp_host,
            smtp_user: smtp_user,
            smtp_password: smtp_password,
            smtp_method: smtp_method,
            smtp_port: smtp_port,
            from_email: from_email,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPaymentSettings = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/get_payment_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addPaymentSettings = async (
  accountno,
  token,
  bank_account_details,
  wire_transfer_details,
  ACH_details
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/add_payment_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            bank_account_details: bank_account_details,
            wire_transfer_details: wire_transfer_details,
            ACH_details: ACH_details,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getQuotationSettings = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/get_quotation_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAssignedQuotationSettings = async (
  accountno,
  token,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_quotation_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            quote_accountno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addQuotationSettings = async (
  accountno,
  token,
  quotation_notes,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/add_quotation_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            quotation_notes: quotation_notes,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getRfqQuestions = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "request_for_quote/get_rfq_questions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addRfqQuestion = async (
  accountno,
  token,
  question_title,
  question_type,
  misc_rows
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "request_for_quote/add_rfq_questions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            question_title: question_title,
            question_type: question_type,
            misc_rows: misc_rows,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editRfqQuestion = async (
  accountno,
  token,
  question_title,
  question_type,
  question_id,
  misc_rows
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/edit_rfq_questions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            question_title: question_title,
            question_type: question_type,
            question_id: question_id,
            misc_rows: misc_rows,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteQuestions = async (accountno, token, question_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "request_for_quote/delete_question",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            question_id: question_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteAnswerOption = async (accountno, token, id, question_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/delete_answer_option",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            id: id,
            question_id: question_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleRfqQuestion = async (accountno, token, question_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_single_rfq_question",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            question_id: question_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listActiveClients = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_active_clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listSingleClient = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "request_for_quote/get_single_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listClients = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getRfqs = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "request_for_quote/get_client_rfqs",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleRfq = async (
  accountno,
  token,
  client_accountno,
  rfq_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_single_client_rfq",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            rfq_accountno: rfq_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getQuotations = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_client_quotations",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleQuotation = async (
  accountno,
  token,
  client_accountno,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_single_quotation",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const createDuplicateQuotation = async (
  accountno,
  token,
  client_accountno,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/create_duplicate_quotation",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationAsDraft = async (
  accountno,
  token,
  discount_type,
  discount_value,
  discount_value_total,
  discount_reason,
  shipping_title,
  shipping_value,
  shipping_value_total,
  tax_type,
  tax_value,
  tax_value_total,
  tax_reason,
  quote_accountno,
  quote_title,
  quote_notes,
  quotation_notes,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  items_list,
  items_list_optional,
  client_accountno,
  rfq_accountno,
  service_grand_sub_total,
  service_grand_total,
  agreement_accountno,
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/save_quotation_as_draft",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            discount_type: discount_type,
            discount_value: discount_value,
            discount_value_total: discount_value_total,
            discount_reason: discount_reason,
            shipping_title: shipping_title,
            shipping_value: shipping_value,
            shipping_value_total: shipping_value_total,
            tax_type: tax_type,
            tax_value: tax_value,
            tax_value_total: tax_value_total,
            tax_reason: tax_reason,
            quote_accountno: quote_accountno,
            quote_title: quote_title,
            quote_notes: quote_notes,
            quotation_notes: quotation_notes,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            items_list: items_list,
            items_list_optional: items_list_optional,
            rfq_accountno: rfq_accountno,
            service_grand_sub_total: service_grand_sub_total,
            service_grand_total: service_grand_total,
            agreement_accountno: agreement_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationAndSent = async (
  accountno,
  token,
  discount_type,
  discount_value,
  discount_value_total,
  discount_reason,
  shipping_title,
  shipping_value,
  shipping_value_total,
  tax_type,
  tax_value,
  tax_value_total,
  tax_reason,
  quote_accountno,
  quote_title,
  quote_notes,
  quotation_notes,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  items_list,
  items_list_optional,
  client_accountno,
  rfq_accountno,
  service_grand_sub_total,
  service_grand_total
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/save_quotation_and_send",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            discount_type: discount_type,
            discount_value: discount_value,
            discount_value_total: discount_value_total,
            discount_reason: discount_reason,
            shipping_title: shipping_title,
            shipping_value: shipping_value,
            shipping_value_total: shipping_value_total,
            tax_type: tax_type,
            tax_value: tax_value,
            tax_value_total: tax_value_total,
            tax_reason: tax_reason,
            quote_accountno: quote_accountno,
            quote_title: quote_title,
            quote_notes: quote_notes,
            quotation_notes: quotation_notes,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            items_list: items_list,
            items_list_optional: items_list_optional,
            rfq_accountno: rfq_accountno,
            service_grand_sub_total: service_grand_sub_total,
            service_grand_total: service_grand_total,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationInformation = async (
  accountno,
  token,
  quote_accountno,
  payment_terms,
  currency,
  contact_person,
  contact_email,
  validity,
  client_accountno,
  agreement_accountno,
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/save_quotation_information",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
            payment_terms: payment_terms,
            currency: currency,
            contact_person: contact_person,
            contact_email: contact_email,
            validity: validity,
            agreement_accountno: agreement_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationComment = async (
  accountno,
  token,
  quote_accountno,
  client_accountno,
  quote_comment
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/save_quotation_comment",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
            quote_comment: quote_comment,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServicesWithPrice = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_services_with_price",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendQuotationEmail = async (
  accountno,
  token,
  quote_accountno,
  quotation_email,
  quotation_email_content
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/send_quotation_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            quote_accountno: quote_accountno,
            quotation_email: quotation_email,
            quotation_email_content: quotation_email_content,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

/********* Sales Orders ********/

export const getSalesOrders = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_client_sales_orders",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleSalesOrder = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_client_single_sales_order",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPaymentMethod = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_sales_order_payment_method",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const markOrderAsPaid = async (
  accountno,
  token,
  orderno,
  payment_notes
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/mark_sales_order_paid",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
            payment_notes: payment_notes,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPaymentLogs = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_sales_order_payment_logs",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getOrderComments = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_sales_order_comments",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendOrderEmail = async (
  accountno,
  token,
  orderno,
  emailto,
  emailcc,
  emailbcc,
  emailsubject,
  emailcontent
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/send_sales_order_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
            emailto: emailto,
            emailcc: emailcc,
            emailbcc: emailbcc,
            emailsubject: emailsubject,
            emailcontent: emailcontent,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const rejectSalesOrder = async (
  accountno,
  token,
  orderno,
  rejection_reason
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/reject_sales_order",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
            rejection_reason: rejection_reason,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

/***********************/

export const getClientNotes = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/get_welcome_notes",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editClientNotes = async (accountno, token, content_data) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/update_welcome_notes",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            content_data: content_data,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleNote = async (
  accountno,
  token,
  client_accountno,
  quote_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/get_single_quotation_internal_note",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveQuotationNote = async (
  accountno,
  token,
  quote_accountno,
  client_accountno,
  quote_note
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "request_for_quote/save_quotation_note",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            quote_accountno: quote_accountno,
            quote_note: quote_note,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};
