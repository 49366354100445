import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { getMarketingCollaterals } from "./../../../config/collateral_apis";
import DataTable from "../../../components/Tables/DataTable";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import { APP_LIVE_URL } from "./../../../config/config";

const auth = new HelperClass();

class Collaterals extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      formLoader: true,
      docErrorMessage: "",
      docSuccessMessage: "",
      document_title: "",
      document_file: [],
      document_file_name: "",
      document_for: "none",
      columnServices: [
        { name: "TITLE" },
        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "DOWNLOAD",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewDownloadBtns(value, tableMeta)}</div>;
            },
          },
        },
        { name: "TYPE" },
        {
          name: "FOR",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.assignedTo(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "STATUS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showAssignedServices(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Assigned Services"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-exchange"></em>
                                  <span>Assign To Agents</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientAgreements(value, tableMeta)
                                  }
                                  title="Client Agreements"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-exchange"></em>
                                  <span>Assign To Clients</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientServices(value, tableMeta)
                                  }
                                  title="Client Notes"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Update Statuses</span>
                                </a>
                              </li>

                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-emails"></em>
                                  <span>Email Collaterals</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showCreditApplications(
                                      value,
                                      tableMeta
                                    )
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-remove"></em>
                                  <span>Delete Collaterals</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await getMarketingCollaterals(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  downloadCreditAppPdf = async (d_f_name) => {
    //this.setState({ processing_request: true });
    var url = APP_LIVE_URL + "files_data/collaterals/" + d_f_name;
    console.log("url: ", url);
    let file_name = d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        //this.setState({ processing_request: false });
      });
  };

  setNewCollateral = async () => {
    this.setState({
      docErrorMessage: "",
      docSuccessMessage: "",
      document_title: "",
      document_file: "",
    });
    window.$("#modalNewCollateral").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  defaultText = (value, tableMeta) => {
    let is_default = value;

    if (is_default === 0) {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  assignedTo = (value, tableMeta) => {
    let is_default = value;

    if (is_default === "none") {
      return (
        <span className="badge badge-outline-secondary">
          None
        </span>
      );
    } else if (is_default === "all") {
      return (
        <span className="badge badge-outline-secondary">
          All Agents and Clients
        </span>
      );
    } else if (is_default === "agents") {
      return (
        <span className="badge badge-outline-secondary">
          Agents
        </span>
      );
    } else if (is_default === "clients") {
      return (
        <span className="badge badge-outline-secondary">
          Clients
        </span>
      );
    }
  };

  viewDownloadBtns = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => this.downloadCreditAppPdf(value)}
            style={{ cursor: "pointer" }}
            title="Download Document"
          >
            <span className="badge badge-outline-secondary">
              {" "}
              <em class="icon ni ni-file-pdf"></em> View Document
            </span>
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-light">
            {" "}
            <em class="icon ni ni-file-pdf"></em> View Document
          </span>
        </div>
      );
    }
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".doc",
      ".docx",
      ".pdf",
      ".csv",
      ".xlsx",
      ".xls",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        docErrorMessage:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        docErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      document_file: selectedFile,
      docErrorMessage: "",
      document_file_name: selectedFile.name,
    });
    //return true;
  };

  saveNewCollateral = async () => {
    const { document_file, document_file_name, document_title, document_for } =
      this.state;
    if (document_title === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Title field is required.",
      });
    } else if (document_file_name === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please choose a document to upload.",
      });
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", document_file);
      formData.append("document_title", document_title);
      formData.append("document_for", document_for);
      formData.append("accountno", auth.getAccount());
      formData.append("fileName", document_file_name);

      axios
        .post(
          process.env.REACT_APP_API_URL +
            "collaterals/add_marketing_collaterals",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log("File Upload RES ", res.data);
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404 && res.data.error === "error") {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage: res.data.message,
            });
            //window.location.replace("/error");
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              docSuccessMessage: "Marketing collateral added successfully.",
              docErrorMessage: "",
              document_file_name: "",
              document_file: "",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage:
                "There is some error while adding the marketing collateral.",
            });
          }
        });
    }
    setTimeout(() => {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "",
      });
    }, 4000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Marketing Collaterals"
                        icon="icon ni ni-file-docs"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        dataTarget="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        dataContent="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <button
                              type="button"
                              onClick={() => this.setNewCollateral()}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Document
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title=""
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* New Client Modal Start */}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalNewCollateral"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-users"
                              style={{ marginRight: "15px" }}
                            ></em>{" "}
                            Add New Document
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.formLoader === true ? (
                            FormLoader()
                          ) : (
                            <div className="row g-4">
                              {this.state.docErrorMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.docErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.docSuccessMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.docSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="document_title"
                                      name="document_title"
                                      type="text"
                                      label="Title"
                                      defaultValue={this.state.document_title}
                                      inputProps={{ maxLength: 64 }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^a-zA-Z0-9 -_]/gi,
                                          ""
                                        );
                                      }}
                                      helperText="Title of the document."
                                      autoFocus
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      id="document_for"
                                      name="document_for"
                                      select
                                      label="Select Document For"
                                      value={this.state.document_for}
                                      onChange={this.handleChange}
                                      SelectProps={{
                                        native: true,
                                      }}
                                      helperText=""
                                      variant="outlined"
                                      fullWidth
                                    >
                                      <option key="sets100" value="none">
                                        None
                                      </option>
                                      <option key="sets101" value="all">
                                        All
                                      </option>
                                      <option key="sets102" value="agents">
                                        Agents
                                      </option>
                                      <option key="sets103" value="clients">
                                        Clients
                                      </option>
                                    </MutextField>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      Upload File
                                      <sup> *</sup>
                                    </label>
                                  </div>
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id="document_file"
                                      className="file-block"
                                      name="document_file"
                                      defaultValue={this.state.document_file}
                                      accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .csv, .xlsx, .xls"
                                      onChange={this.handleFileChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex justify-content-end">
                                <button
                                  type="button"
                                  onClick={() => this.saveNewCollateral()}
                                  className="btn btn-primary"
                                  disabled={this.state.disabled}
                                >
                                  <span>Submit</span>{" "}
                                  <em className="icon ni ni-emails"></em>
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="example-alert">
                                  <div className="alert alert-light">
                                    <ul className="list">
                                      <li>
                                        An email will be dispatched to the
                                        provided email address.
                                      </li>
                                      <li>
                                        This email will contain a secure,
                                        tokenized link for client registration.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* New Client Modal End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Collaterals);
