import { Link } from "react-router-dom";
import { LOGO } from "./../../config/config";
import $ from "jquery";
import HelperClass from "./../../config/helperClass";
const auth = new HelperClass();
const leftSideBar = (props) => {
  console.log("props.menuArr: ", props);
  /*function classCheck() {
    var menu_active = $(this);
    var menu_items = $("#reportmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }*/

  function classCheck(id) {
    var menu_items = $("#" + id);
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function activeCheck() {
    var menu_active = $(this);
    console.log("menu active has: ", menu_active);
    if (menu_active.hasClass("active")) {
      $(this).removeClass("active");
      $(this).removeClass("current-page");
    } else {
      $(this).addClass("active");
      $(this).addClass("current-page");
    }
  }

  const addMenuHistoryLeft = (link) => {
    props.mainProps.history.push(link);
  };

  return (
    <div className="nk-sidebar nk-sidebar-fixed" data-content="sidebarMenu">
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{
          backgroundColor: "white",
          height: "63.5px",
          borderRight: "1px solid #e8ecec",
        }}
      >
        <div className="nk-sidebar-brand">
          <div className="logo-link nk-sidebar-logo">
            <img className="logo-light logo-img" src={LOGO} alt="logo" />
            <img
              className="logo-dark logo-img"
              src={LOGO}
              alt="logo-dark"
              style={{ marginLeft: "0" }}
            />
          </div>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <div
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </div>
        </div>
      </div>
      <div
        className="nk-sidebar-element"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              <li className="nk-menu-heading">
                <h3
                  className="overline-title text-primary-alt"
                  style={{ color: "#6576ff" }}
                >
                  Admin Portal
                </h3>
              </li>
              <li className="nk-menu-heading">
                <h3
                  className="overline-title text-primary-alt"
                  style={{ color: "#202223" }}
                >
                  {auth.getCompName()}
                </h3>
              </li>

              {props.menuArr && props.menuArr.length > 0 ? (
                <>
                  {props.menuArr.map((mainMenu, index) => (
                    <>
                      {mainMenu.submenu && mainMenu.submenu.length > 0 ? (
                        <li
                          className="nk-menu-item has-sub"
                          onClick={() =>
                            classCheck(mainMenu.alt.replace(" ", ""))
                          }
                          id={mainMenu.alt.replace(" ", "")}
                          key={`mainmenu${index}`}
                        >
                          <div className="nk-menu-link nk-menu-toggle">
                            <span className="nk-menu-icon">
                              <em className={mainMenu.tabimage}></em>
                            </span>
                            <span
                              className="nk-menu-text"
                              style={{
                                fontSize: "1.1rem",
                                marginLeft: "5%",
                                cursor: "pointer",
                              }}
                            >
                              {mainMenu.alt}
                            </span>
                          </div>
                          <ul className="nk-menu-sub">
                            {mainMenu.submenu.map((datas, k) => (
                              <li className="nk-menu-item" key={`submenu${k}`}>
                                {datas.link === "/upgrades/general_settings" || datas.link === "/upgrades/smtp_settings" || datas.link === "/upgrades/payment_settings" || datas.link === "/upgrades/quotation_settings" ? (
                                  <a className="nk-menu-link" href={datas.link} >
                                  <span
                                    className="nk-menu-text"
                                    style={{ color: "#202223" }}
                                  >
                                    {datas.alt}
                                  </span>
                                </a>
                                ) : (
                                  <Link className="nk-menu-link" to={datas.link} >
                                  <span
                                    className="nk-menu-text"
                                    style={{ color: "#202223" }}
                                  >
                                    {datas.alt}
                                  </span>
                                </Link>
                                )}
                                
                              </li>
                            ))}
                          </ul>
                        </li>
                      ) : (
                        <li
                          className="nk-menu-item"
                          onClick={() => activeCheck()}
                          key={`mainmenu${index}`}
                        >
                          <Link className="nk-menu-link" to={mainMenu.link}>
                            <span className="nk-menu-icon">
                              <em className={mainMenu.tabimage}></em>
                            </span>
                            <span
                              className="nk-menu-text"
                              style={{
                                fontSize: "1.1rem",
                              }}
                            >
                              {mainMenu.alt}
                            </span>
                          </Link>
                        </li>
                      )}
                    </>
                  ))}
                </>
              ) : null}

              {/*<li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link className="nk-menu-link" onClick={() => dashboardLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-dashboard"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                    }}
                  ></span>
                </Link>
              </li>

              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link className="nk-menu-link" onClick={() => dashboardLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-dashboard"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    Dashboard
                  </span>
                </Link>
              </li>

              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link
                  className="nk-menu-link"
                  onClick={() => addMenuHistoryLeft("/clients")}
                >
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-user-list"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Manage Clients
                  </span>
                </Link>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => classCheck32()}
                id="servicesmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-file-docs"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Manage Services
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => addMenuHistoryLeft("/services")}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Services
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => addMenuHistoryLeft("/clients/agreements")}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Service Agreements
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => addMenuHistoryLeft("/package_agreements")}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Package Agreements
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => classCheck2()}
                id="agentmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-user-list"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Agents
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => addMenuHistoryLeft("/agents")}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Agents
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => addMenuHistoryLeft("/agents/agreements")}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Agent Agreements
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => addMenuHistoryLeft("/domains")}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Domains
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => classCheck()}
                id="reportmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-file-docs"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Reports
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailyOrigStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily Orig. Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailyTermStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily Term. Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailySmsStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily SMS Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailyDidStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily DID Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailyRevenue()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily Revenue
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggOrigStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate Orig. Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggTermStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate Term. Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggSmsStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate SMS Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggDidStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate DID Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggRevenueStats()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate Revenue
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nk-menu-item">
                <Link className="nk-menu-link" onClick={() => analyticsLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-growth"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Analytics
                  </span>
                </Link>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => classCheck2()}
                id="webmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-file-docs"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem", 
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Website
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link onClick={() => cmsLeft()} className="nk-menu-link">
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage CMS
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link onClick={() => themesLeft()} className="nk-menu-link">
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Themes
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => domainsLeft()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Domains
                      </span>
                    </Link>
                  </li>
                </ul>
                  </li>

              <li className="nk-menu-item">
                <Link className="nk-menu-link" onClick={() => agreementsLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-grid-add-c"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Signed Agreements
                  </span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link className="nk-menu-link" onClick={() => upgradesLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-setting"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Settings
                  </span>
                </Link>
              </li>
              {props.routingUrl !== "" ? (
                <li className="nk-menu-item" key="mainmenu223">
                  <Link
                    className="nk-menu-link"
                    onClick={() => {
                      window.open(props.routingUrl, "_blank");
                    }}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-arrow-right-c"></em>
                    </span>
                    <span
                      className="nk-menu-text"
                      style={{ fontSize: "1.1rem" }}
                    >
                      Routing Portal
                    </span>
                  </Link>
                </li>
              ) : null}*/}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default leftSideBar;
